<template>
  <div class="child-page-div">
    <div class="page-content-div">
      <table-box ref="tableBox" :tableTitle="'服务入驻审核'" :tableFieldNum="tableFieldNum" :action="action" :params="queryParams" :reloadData.sync="reloadData">
        <template #title-right>
          <div class="search-div">
            <label>
              <select class="prefecture-select" v-model="provinceId" @change="onProvinceCity($event)">
                <option value="">全部省份</option>
                <option v-for="item in prefecture.provinces" :value="item.id">{{item.name}}</option>
              </select>
            </label>
            <label>
              <select class="prefecture-select" v-model="cityId" @change="onProvinceCounty($event)">
                <option value="">全部城市</option>
                <option v-for="item in prefecture.cities" :value="item.id">{{item.name}}</option>
              </select>
            </label>
            <label>
              <select class="prefecture-select" v-model="areaId">
                <option value="">全部地区</option>
                <option v-for="item in prefecture.counties" :value="item.id">{{item.name}}</option>
              </select>
            </label>
            <label>
              <select class="role-select" v-model="role">
                <option value="">全部角色</option>
                <option value="21">修理工</option>
                <option value="22">驾驶员</option>
                <option value="23">拖车</option>
                <option value="24">车床加工</option>
                <option value="25">电焊</option>
                <option value="26">压油管</option>
                <option value="27">五金店</option>
                <option value="28">销售员</option>
                <option value="29">物流</option>
              </select>
            </label>
            <label class="state-label">
              <select v-model="verifyState">
                <option value="">全部状态</option>
                <option value="3">待审核</option>
                <option value="1">申请通过</option>
                <option value="0">申请驳回</option>
              </select>
            </label>
            <label>
              <select v-model="searchType">
                <option value="1">姓名</option>
              </select>
            </label>
            <label>
              <input class="search-input" type="text" maxlength="20" v-model="searchMsg" placeholder="搜索相关数据..." />
            </label>
            <input class="search-btn" type="button" value="搜索" @click="onSearch" />
          </div>
        </template>
        <template #table-head>
          <th v-for="(item, index) in tableField" v-show="item.state" :key="index">{{ item.alias }}</th>
          <th v-if="isOperation" class="operation-th">操作</th>
        </template>
        <template #table-body="props">
          <tr>
            <template v-for="(item, index) in tableField">
              <td v-if="item.isHtml" :key="index" v-show="item.state" v-html="item.value(props.item, item.name)" />
              <td v-else :key="index" v-show="item.state">{{ item.value(props.item, item.name) }}</td>
            </template>
            <td v-if="isOperation">
              <div class="operation-btn-div">
                <span class="cursor-el" @click="onShowDetails(props.item.userId)">查看</span>
              </div>
            </td>
          </tr>
        </template>
      </table-box>
    </div>
  </div>
</template>

<script>
import TableBox from "../../components/TableBox";
import ProviderApplyLayer from "../layer/ProviderApplyLayer";
import { getProvinces, getCities, getCounties, getProviderItems } from "../../common/api";

export default {
  name: "ProviderSettled",
  components: { TableBox },
  data() {
    return {
      action: getProviderItems,
      reloadData: false,
      prefecture: {
        provinces: [],
        cities: [],
        counties: []
      },
      provinceId: "",
      cityId: "",
      areaId: "",
      role: "",
      verifyState: "",
      searchType: "1",
      searchMsg: "",
      queryParams: {
        type: 0,
        msg: "",
        provinceId: "",
        cityId: "",
        areaId: "",
        role: "",
        state: "",
        pageSize: 20
      },
      tableField: [
        { state: true, alias: "编号", name: "userId", title: "userId", isHtml: false, value: this.getObjValue },
        { state: true, alias: "名称", name: "name", title: "name", isHtml: false, value: this.getObjValue },
        { state: true, alias: "身份", name: "roleName", title: "roleName", isHtml: false, value: this.getObjValue },
        { state: true, alias: "联系电话", name: "mobile", title: "mobile", isHtml: false, value: this.getObjValue },
        { state: true, alias: "申请时间", name: "createTime", title: "createTime", isHtml: false, value: this.getObjValue },
        { state: true, alias: "状态", name: "state", title: "state", isHtml: true, value: this.getObjValue },
        { state: true, alias: "审核人", name: "reviewer", title: "reviewer", isHtml: false, value: this.getObjValue }
      ]
    };
  },
  computed: {
    isOperation() {
      return this.$hasAuthority(2200000,2202000,[2202001,2202002]);
    },
    tableFieldNum() {
      let num = this.isOperation ? 1 : 0;
      this.tableField.forEach(function(value) {
        if (value.state) {
          ++num;
        }
      });
      return num;
    }
  },
  created() {
    this.initProvinceData();
  },
  methods: {
    getObjValue(item, field) {
      if(field === "state"){
        return item[field] === 1 ? "<span class='success-txt'>已通过</span>" : (item[field] === 3 ? "<span>待审核</span>" : "<span class='red-txt'>未通过</span>");
      }else{
        return item[field];
      }
    },
    initProvinceData() {
      getProvinces()
          .then(data => {
            this.prefecture.provinces = data;
          })
          .catch(error => {
            if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
              this.$layer.msg(error.data.msg);
            } else {
              this.$layer.msg("查询省份信息失败，请稍后重试");
            }
          });
    },
    onProvinceCity(event) {
      let provinceId = event.target.value;
      if(provinceId > 0){
        getCities({provinceId: provinceId})
            .then(data => {
              this.prefecture.cities = data;
              this.prefecture.counties = [];
              this.cityId = "";
              this.areaId = "";
            })
            .catch(error => {
              if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
                this.$layer.msg(error.data.msg);
              } else {
                this.$layer.msg("查询城市信息失败，请稍后重试");
              }
            });
      }else{
        this.prefecture.cities = [];
        this.prefecture.counties = [];
        this.cityId = "";
        this.areaId = "";
      }
    },
    onProvinceCounty(event) {
      let cityId = event.target.value;
      if(cityId > 0){
        getCounties({cityId: cityId})
            .then(data => {
              this.prefecture.counties = data;
              this.areaId = "";
            })
            .catch(error => {
              if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
                this.$layer.msg(error.data.msg);
              } else {
                this.$layer.msg("查询地区信息失败，请稍后重试");
              }
            });
      }else{
        this.prefecture.counties = [];
        this.areaId = "";
      }
    },
    onReloadData() {
      this.reloadData = true;
    },
    onSearch() {
      this.queryParams.type = this.searchType;
      this.queryParams.msg = this.searchMsg.trim();
      this.queryParams.state = this.verifyState;
      this.queryParams.provinceId = this.provinceId;
      this.queryParams.cityId = this.cityId;
      this.queryParams.areaId = this.areaId;
      this.queryParams.role = this.role;
    },
    onShowDetails(userId) {
      this.$layer.iframe({
        content: {
          content: ProviderApplyLayer,
          parent: this,
          data: {
            userId: userId,
            refreshData: this.onReloadData
          }
        },
        area: ["800px", "550px"],
        title: "服务入驻申请"
      });
    }
  }
}
</script>

<style lang="less" scoped>
.child-page-div {
  width: 100%;
  .page-content-div {
    border-radius: 5px;
    background-color: #ffffff;
    padding: 30px 20px 10px 20px;
    .search-div {
      height: 100%;
      display: flex;
      select {
        width: 110px;
        height: 100%;
        border: 1px solid #dcdcdc;
      }
      .state-label {
        margin-left: 5px;
        margin-right: 10px;
      }
      .search-input {
        width: 140px;
        height: 33px;
        border: 1px solid #dcdcdc;
        padding: 0 15px;
        margin-left: 5px;
      }
      .search-btn {
        width: 80px;
        height: 100%;
        color: #f4f6f5;
        border: none;
        background: #4676c8;
      }
      .role-select {
        margin-left: 5px;
      }
    }
    .operation-th {
      width: 60px;
    }
    .operation-btn-div {
      display: flex;
      justify-content: space-around;
    }
  }
}
</style>