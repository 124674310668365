<template>
  <div class="layer-page-div">
    <div class="apply-img-div" v-show="isShowImg" @click="onHideImgDiv">
      <auth-img :auth-src="merchantImg" />
    </div>
    <div class="layer-content-div">
      <div class="item-div">
        <div class="item-name-div">名称</div>
        <div>{{ providerInfo.name}}</div>
      </div>
      <div class="item-div">
        <div class="item-name-div">身份</div>
        <div>{{ providerInfo.roleName }}</div>
      </div>
      <div class="item-div">
        <div class="item-name-div">手机号码</div>
        <div>{{ providerInfo.mobile }}</div>
      </div>
      <div class="item-div">
        <div class="item-name-div">常驻地址</div>
        <div>{{ providerInfo.prefecture }}</div>
      </div>
      <div class="item-div">
        <div class="item-name-div">详细地址</div>
        <div>{{ providerInfo.address }}</div>
      </div>
      <div class="item-div">
        <div class="item-name-div">营业执照</div>
        <div>
          <label class="cursor-el" v-if="hasLicenseImg()" @click="onShowLicenseImg">查看</label>
          <label v-else>无</label>
        </div>
      </div>
      <template v-if="providerInfo.state === 3">
        <div class="text-div">
          <label v-show="isRefuse">
            <textarea v-model="rejectMsg" maxlength="50" placeholder="请输入拒绝理由" />
          </label>
        </div>
        <div class="operate-div text-c">
          <div class="tips-div text-c err-tips">{{ tipMsg }}</div>
          <input v-show="!isRefuse" type="button" value="通过" @click="onPass" />
          <input v-show="isRefuse" type="button" value="确定" @click="onReject" />
          <input v-show="!isRefuse" class="refuse-input" type="button" value="拒绝" @click="onRefuse" />
        </div>
      </template>
      <template v-else>
        <div v-if="providerInfo.state === 1">
          <div class="pass-txt-div text-c success-txt">申请通过</div>
          <div>
            <label class="result-label">审核人:</label>
            <label>{{ providerInfo.reviewer }}</label>
          </div>
          <div>
            <label class="result-label">审核时间:</label>
            <label>{{ providerInfo.auditTime }}</label>
          </div>
        </div>
        <div v-else>
          <div class="text-c red-txt">申请驳回</div>
          <div class="text-div">
            <label>
              <textarea placeholder="请输入拒绝理由" readonly>{{ providerInfo.rejectMsg }}</textarea>
            </label>
          </div>
          <div class="reject-manager">
            <label class="result-label">审核人:</label>
            <label>{{ providerInfo.reviewer }}</label>
          </div>
          <div>
            <label class="result-label">审核时间:</label>
            <label>{{ providerInfo.auditTime }}</label>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import AuthImg from "../../components/AuthImg"
import { getProviderDetails, providerLicenseImg, postProviderPass, postProviderReject } from "../../common/api";

export default {
  components: { AuthImg },
  props: {
    layerid: {
      type: String,
      default: ""
    },
    userId: {
      type: String,
      default: ""
    },
    refreshData: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      isRefuse: false,
      providerInfo: {},
      isShowImg: false,
      merchantImg: "",
      rejectMsg: "",
      tipMsg: ""
    }
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      getProviderDetails({ userId: this.userId })
        .then(data => {
          this.providerInfo = data;
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("查询服务入驻申请信息失败");
          }
        });
    },
    hasLicenseImg() {
      return Object.prototype.hasOwnProperty.call(this.providerInfo, "licenseImg") && this.providerInfo.licenseImg > 0;
    },
    onShowLicenseImg() {
      this.isShowImg = true;
      this.merchantImg = providerLicenseImg(this.userId);
    },
    onHideImgDiv() {
      this.isShowImg = false;
    },
    onRefuse() {
      this.isRefuse = true;
    },
    onPass() {
      postProviderPass({userId: this.userId})
        .then(() => {
          this.refreshData();
          this.$layer.close(this.layerid);
          this.$layer.msg("操作成功");
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("操作失败");
          }
        });
    },
    onReject() {
      if(this.rejectMsg.length < 1){
        this.setTipsMsg("请输入驳回原因");
        return;
      }
      if(this.rejectMsg.length > 50){
        this.setTipsMsg("驳回原因不能超过50个字符，请重新输入");
        return;
      }

      postProviderReject({userId: this.userId, msg: this.rejectMsg})
        .then(() => {
          this.refreshData();
          this.$layer.close(this.layerid);
          this.$layer.msg("操作成功");
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("操作失败");
          }
        });
    },
    setTipsMsg(msg) {
      this.tipMsg = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.tipMsg = "";
      }, 3000);
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .apply-img-div {
    position: absolute;
    width: 100%;
    height: calc(100% - 42px);
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      align-items: center;
    }
  }
  .layer-content-div {
    width: 360px;
    margin: 20px auto 0 auto;
    .item-div {
      margin-top: 15px;
      display: flex;
      .item-name-div {
        width: 60px;
        text-align: right;
        margin-right: 15px;
      }
      .other-img-div {
        label {
          margin-right: 5px;
        }
      }
    }
    .text-div {
      height: 100px;
      margin-top: 15px;
      textarea {
        width: calc(100% - 10px);
        height: 100%;
        padding: 3px;
        resize: none;
        border: 1px solid #e5e5e5;
        border-radius: 4px;
      }
    }
    .pass-txt-div {
      width: 100%;
      height: 120px;
      line-height: 120px;
      font-size: 18px;
      font-weight: bolder;
      letter-spacing: 2px;
    }
    .reject-manager {
      margin-top: 15px;
    }
    .result-label {
      margin-right: 15px;
    }
    .operate-div {
      margin-top: 20px;
      input {
        width: 120px;
        height: 35px;
        color: white;
        font-size: 16px;
        border: none;
        background: #4676c8;
        margin-top: 10px;
      }
      .refuse-input {
        margin-left: 5px;
      }
      .tips-div {
        width: 100%;
        height: 25px;
        margin-top: 10px;
      }
    }
  }
}
</style>